// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs, addDoc } from 'firebase/firestore';
import { getStorage } from "firebase/storage";
import { getAuth, } from "firebase/auth";
// 기본
//파이어베이스 로그인
// 보안
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGIN_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

const actionCodeSettings = {
  // URL you want to redirect back to. The domain (www.example.com) for this
  // URL must be in the authorized domains list in the Firebase Console.
  url: 'www.http://potato-salon.shop/SLogin',
  // This must be true.
  handleCodeInApp: true,
  iOS: {
    bundleId: ''
  },
  android: {
    packageName: '',
    installApp: true,
    minimumVersion: '12'
  },
  dynamicLinkDomain: ''
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const Storage = getStorage(app);
const auth = getAuth(app);

//db
export { app, db, Storage, auth, actionCodeSettings};
// 스토리지

//로그인




