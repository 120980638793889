import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import { ReactComponent as Facebook } from 'pages/Component/img-806/facebook.svg'
import { ReactComponent as Instagram } from 'pages/Component/img-806/instagram.svg'
import { ReactComponent as Naver } from "pages/Component/img-806/naver.svg";
import { ReactComponent as Youtube } from 'pages/Component/img-806/youtube.svg'


const Footer = () => {
    return (
        <div>
            <footer className="footer-top">
                <div className="footer-middle">
                    <div className="footer-middle-textcontainer">
                        <p className="text-box-main">ⓒ심리살롱</p>
                        <p className="text-box-main">상호명 : 수원북카페 심리살롱 ㅣ 대표자 : 김성준<br />
                            주소:경기 수원시 영통구 월드컵로193번길 59-1 1층<br />
                            사업자등록번호 : ??? ㅣ 통신판매번호 : 제 ??? ㅣ 이메일 :<p className="text-link"><Link to="https://mail.naver.com/">elliot0101@naver.com</Link></p>
                        </p>

                        <p className="text-box-main">수원북카페 심리살롱은 모든 거래에 대한 책임과 배송, 교환, 환불, 민원 등의 처리는<br />수원북카페 심리살롱에서 진행합니다.<br />
                            자세한 문의는 우측 하단의 '채널톡'을 통해 가능합니다.
                        </p>
                        <p className="text-box-boder">이용약관 ㅣ 개인정보처리방침</p>
                        <p className="text-box">Copyright ⓒ {new Date().getFullYear()} 심리살롱 All rights reserved.</p>
                    </div>
                </div>

                <div className="footer-container-icon">
                    <div className="footer-icon">
                        <Link to="https://www.facebook.com/profile.php?id=100080693672310" target='_blank'><Facebook width="35" height="35" fill="#000" /></Link>
                    </div>
                    <div className="footer-icon">
                        <Link to="https://www.instagram.com/potatosalon/" target='_blank'><Instagram width="35" height="35" fill="#000" /></Link>
                    </div>
                    <div className="footer-icon">
                        <Link to="https://brunch.co.kr/@cowboy138" target='_blank'><Naver width="35" height="35" fill="#000" /></Link>
                    </div>
                    <div className="footer-icon">
                        <Link to="/" target='_blank'><Youtube width="35" height="34" fill="#000" /></Link>
                    </div>
                </div>
            </footer >
        </div >
    );
};






export default Footer;