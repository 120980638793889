import React from "react";
import { Link } from 'react-router-dom';

import "./Itam2.css"
import book from "pages/Component/img-806/book.png"
import styled from 'styled-components';



import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css";
import "swiper/css/navigation";
import 'swiper/css/pagination';

import { Autoplay, Navigation } from "swiper";

const Itam2 = () => {

  const Media = styled.div`
  @media screen and (max-width: 600px) {
    .itam2-img-box{
      width: 140px;
      height: 125px;
    }
  }`;

  const FontMedia = styled.p`
   @media screen and (max-width: 600px) {
    .itam2-img-font-box{
      font-size: 17px;
    }
  }`;

  const Itamsm = styled.div`
   @media screen and (max-width: 600px) {
    .itam2-harder{
      font-size: 13px;
      gap: 1%;
    }
  }`;

  const Spans = styled.span`
      font-size: 13px;
      color: #69efd0;
    `;

  return (
    <>
      <Itamsm>
        <div className="itam2-harder">
          <h2>7월, 올해 휴가는 독서 모임으로 떠나요.</h2>
          <div className="itam2-harder-link">
            <Link to="/">더보기</Link>
          </div>
        </div>
      </Itamsm>

      <div className="itam2-img-container">
        <Swiper
          rewind={true}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          modules={[Autoplay, Navigation]}

          breakpoints={{
            200: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            470: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
            700: {
              slidesPerView: 4,
              spaceBetween: 40,
            },
          }}

          className="mySwiper"
        >

          <SwiperSlide>
            <Link to="/">
              <FontMedia>
                <Media>
                  <div className="hh">
                    <img className="itam2-img-box" src={book} alt="prop" />
                    <div className="itam2-img-font-box">
                      <h3>체험독서-예술</h3>
                      <p>당신이 예술에 행복해하는 사람이라면, 여기서 만나요.</p>
                      <p><Spans>강남 아지트</Spans></p>
                      <p>첫 모임 7/7(금) 20:00</p>
                    </div>
                  </div>
                </Media>
              </FontMedia>
            </Link>
          </SwiperSlide>
          <SwiperSlide>
            <Link to="/">
              <FontMedia>
                <Media>
                  <div>
                    <img className="itam2-img-box" src={book} alt="prop" />
                    <div className="itam2-img-font-box">
                      <h3>체험독서-예술</h3>
                      <p>당신이 예술에 행복해하는 사람이라면, 여기서 만나요.</p>
                      <p><Spans>강남 아지트</Spans></p>
                      <p>첫 모임 7/7(금) 20:00</p>
                    </div>
                  </div>
                </Media>
              </FontMedia>
            </Link>
          </SwiperSlide>
          <SwiperSlide>
            <Link to="/">
              <FontMedia>
                <Media>
                  <div>
                    <img className="itam2-img-box" src={book} alt="prop" />
                    <div className="itam2-img-font-box">
                      <h3>체험독서-예술</h3>
                      <p>당신이 예술에 행복해하는 사람이라면, 여기서 만나요.</p>
                      <p><Spans>강남 아지트</Spans></p>
                      <p>첫 모임 7/7(금) 20:00</p>
                    </div>
                  </div>
                </Media>
              </FontMedia>
            </Link>
          </SwiperSlide>
          <SwiperSlide>
            <Link to="/">
              <FontMedia>
                <Media>
                  <div>
                    <img className="itam2-img-box" src={book} alt="prop" />
                    <div className="itam2-img-font-box">
                      <h3>체험독서-예술</h3>
                      <p>당신이 예술에 행복해하는 사람이라면, 여기서 만나요.</p>
                      <p><Spans>강남 아지트</Spans></p>
                      <p>첫 모임 7/7(금) 20:00</p>
                    </div>
                  </div>
                </Media>
              </FontMedia>
            </Link>
          </SwiperSlide>
          <SwiperSlide>
            <Link to="/">
              <FontMedia>
                <Media>
                  <div>
                    <img className="itam2-img-box" src={book} alt="prop" />
                    <div className="itam2-img-font-box">
                      <h3>체험독서-예술</h3>
                      <p>당신이 예술에 행복해하는 사람이라면, 여기서 만나요.</p>
                      <p><Spans>강남 아지트</Spans></p>
                      <p>첫 모임 7/7(금) 20:00</p>
                    </div>
                  </div>
                </Media>
              </FontMedia>
            </Link>
          </SwiperSlide>
        </Swiper>
      </div >
    </>
  );
};

export default Itam2;