
import React, {
    useState,
    useEffect
} from "react";
import {
    onAuthStateChanged, // 코드 추가
    signInWithEmailAndPassword, // 코드 추가
} from "firebase/auth";
import { auth } from "pages/ManagerComponent/firebase/firebaseDb";

import { Link } from "react-router-dom";
import "./Loginfrom.css";




const Loginform = () => {

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    const [emailValid, setEmailValid] = useState(false)
    const [passwordValid, setPasswordValid] = useState(false)

    const [submitButton, setSubmitButton] = useState(true)
    const [errorMsg, setErrorMsg] = useState("　");
    const [user, setUser] = useState("")

    const loginSubmit = async () => {
        try {
            const curUserInfo = await signInWithEmailAndPassword(
                auth, email, password);
            // console.log(curUserInfo);
            setUser(curUserInfo.user);
        } catch (err) {
            console.log(err.code)
            switch (err.code) {
                case 'auth/user-not-found':
                    setErrorMsg('입력하신 이메일 또는 비밀번호가 일치하지 않습니다');
                    break;
                case 'auth/wrong-password':
                    setErrorMsg('비밀번호를 다시 확인해주세요');
                    break;
            }
        }
    }


    const Handleremail = (e) => {

        setEmail(e.target.value);
        const regex =
            /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
        if (regex.test(e.target.value)) {
            setEmailValid(true)
        } else {
            setEmailValid(false)
        }
    };


    const Handlerpassword = (e) => {
        setPassword(e.target.value);
        const regex =
            /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,}$/;
        if (regex.test(e.target.value)) {
            setPasswordValid(true);
        } else {
            setPasswordValid(false);
        }
    };


    useEffect(() => {
        if (emailValid && passwordValid) {
            setSubmitButton(false);
            return;
        }
        setSubmitButton(true);
    }, [emailValid, passwordValid])



    console.log(email, password)

    return (
        <div>

            <form
                className="eamilpasswordinput">
                <div className="">
                    <div className="ggg">
                        <input
                            className="gmailinput"
                            placeholder="이메일"
                            type="email"
                            value={email}
                            onChange={Handleremail} />
                    </div>
                    <div
                        style={{
                            marginLeft: "10px",
                            fontSize: "13px",
                            color: "#ef4545"
                        }}>
                        {!emailValid && email.length > 0 && (
                            <div>올바른 이메일을 입력해주세요.</div>
                        )}
                    </div>
                </div>
                <div>
                    <input
                        className="passwordinput"
                        placeholder="비밀번호"
                        type="password"
                        value={password}
                        onChange={Handlerpassword} />
                    <div
                        style={{
                            marginLeft: "10px",
                            fontSize: "13px",
                            color: "#ef4545"
                        }}>
                        {!passwordValid && password.length > 0 && (
                            <div>영문, 숫자,특수문자 포함 8글자 입력해주세요.</div>
                        )}
                    </div>
                    <div
                        className="SLogin_id_pw">
                        <div
                            className="SLogin">
                            <Link to="/SLogin">회원가입</Link >
                        </div>
                        <div
                            className="id_pw">
                            <div
                                className="idfind">
                                <Link to="/">
                                    아이디 찾기
                                </Link>
                            </div>
                            <p
                                style={{ color: "rgba(75, 73, 73, 0.782)" }}>
                                ㅣ
                            </p>
                            <div
                                className="pwfind">
                                <Link to="/">
                                    비밀번호 찾기
                                </Link>
                            </div>
                        </div>
                    </div>

                </div>
                <br />
                <button
                    className="submitbutton"
                    type="submit"
                    disabled={submitButton}
                    onClick={loginSubmit}
                >
                    로그인
                </button>
            </form>
            <div>{user?.email}</div>
        </div>

    );
};
export default Loginform;