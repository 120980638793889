import React, { Component, useState, useEffect, useId, useRef, any } from "react";
import "./Managercomponent.css"
import { Link } from 'react-router-dom';
// 파이어베이서 파일에서 import 해온 db
import { db, Storage, auth, } from './firebase/firebaseDb';
// db에 접근해서 데이터를 꺼내게 도와줄 친구들
import { collection, getDocs, updateDoc, addDoc, doc, deleteDoc } from "firebase/firestore";
import async from '@firebase/util';

//   파이어베이스 이미지
import { getStorage, ref, uploadBytes, listAll, getDownloadURL, } from "firebase/storage";
import { useDispatch, useSelector } from "react-redux";
//   파이어베이스 이미지

//파이어베이스 스토리지 이미지 가져오기



const Managercomponent = () => {

    console.log(auth.currentUser);
    // input으로 받을 새로운 사람의 이름과 나이
    const [newName, setNewName] = useState("");
    const [newAge, setNewAge] = useState(0);

    // 이미지영역
    const [imgUpload, setimgUpload] = useState(null);
    const imgList = useSelector((state) => state);
    const imgListRef = ref(Storage, "imgList/");
    const dispatch = useDispatch();



    const addImg = (imgUrl) => {
        dispatch({ type: "ADD_IMG", payload: imgUrl });
    };

    const setImgList = (imgList) => ({
        type: "SET_IMG_LIST",
        payload: imgList,
      });

    const upload = async () => {
        if (imgUpload === null) return;

        const imgRef = ref(Storage, `imgUpload/${imgUpload.name}`);
        // `images === 참조값이름(폴더이름), / 뒤에는 파일이름 어떻게 지을지
        await uploadBytes(imgRef, imgUpload).then((snapshot) => {
            // 업로드 되자마자 뜨게 만들기
            getDownloadURL(snapshot.ref).then((url) => {
                setImgList((prev) => [...prev, url]);
            });
        });
    };
    useEffect(() => {
        listAll(imgListRef).then((response) => {
            response.items.forEach((item) => {
                getDownloadURL(item).then((url) => {
                    setImgList((prev) => [...prev, url]);
                });
            });
        });
    }, []);
    // 이미지영역

    // changed를 true로 바꿔주면 되지않을까?
    const [changed, setChanged] = useState(true);

    console.log(newName, newAge);

    // 이따가 users 추가하고 삭제하는거 진행을 도와줄 state
    const [users, setUsers] = useState([]);
    // db의 users 컬렉션을 가져옴
    const usersCollectionRef = collection(db, "users");

    // 유니크 id를 만들기 위한 useId(); - react 18 기능으로, 이 훅을 이렇게 사용하는게 맞고 틀린지는 모른다.
    const uniqueId = useId();
    //console.log(uniqueId)

    // 시작될때 한번만 실행 // 읽어오기 - R
    useEffect(() => {
        // 비동기로 데이터 받을준비
        const getUsers = async () => {
            // getDocs로 컬렉션안에 데이터 가져오기
            const data = await getDocs(usersCollectionRef);
            // users에 data안의 자료 추가. 객체에 id 덮어씌우는거
            setUsers(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
        }

        getUsers();
        // 뭐든 동작할때마다 changed가 true값으로 변경되니까 화면을 그리고 다시 false로 돌려줘야지 다시 써먹는다.
        setChanged(false)
    }, [changed]) // 처음에 한번 그리고, changed가 불릴때마다 화면을 다시 그릴거다

    // 생성 - C
    const createUsers = async () => {
        // addDoc을 이용해서 내가 원하는 collection에 내가 원하는 key로 값을 추가한다.
        const newDoc = await addDoc(usersCollectionRef, { name: newName, age: Number(newAge) });
        // 화면 업데이트를 위한 state 변경
        setChanged(true);
        return newDoc.id;
    }

    // 업데이트 - U
    const updateUser = async (id, age) => {
        // 내가 업데이트 하고자 하는 db의 컬렉션의 id를 뒤지면서 데이터를 찾는다
        const userDoc = doc(db, "users", id)
        // 내가 업데이트 하고자 하는 key를 어떻게 업데이트할지 준비,, 중요한점이 db에는 문자열로 저장되어있다. 그래서 createUsers()함수안에서 age를 생성할때 숫자열로 형변환 해줘야한다
        const newField = { age: age + 1 };
        // updateDoc()을 이용해서 업데이트
        await updateDoc(userDoc, newField);
        // 화면 업데이트를 위한 state 변경
        setChanged(true)
    }


    // 삭제 - D
    const deleteUser = async (id) => {
        // 내가 삭제하고자 하는 db의 컬렉션의 id를 뒤지면서 데이터를 찾는다
        const userDoc = doc(db, "users", id);
        // deleteDoc을 이용해서 삭제
        await deleteDoc(userDoc);
        // 화면 업데이트를 위한 state 변경
        setChanged(true)
    }



    // 띄워줄 데이터 key값에 고유ID를 넣어준다.
    const showUsers = users.map((value) => (<div key={users}>
        <h1>제목: {value.name}</h1>
        <h1>일정: {value.age}</h1>
        {imgList.map((el) => {
            return <img className="itam1-img-box" key={el} src={el.imgListRef} alt="prop" />;
        })}
        {/* <button onClick={() => { updateUser(value.id, value.age, { createUsers }) }}>s</button> */}
        {/* 증가버튼은 이 안에 있어야지, 각기 다른 데이터마다 붙는다, users data를 map으로 돌기때문에, 그 안의 id랑 age를 넣어주면 된다.*/}
        {/* id를 넣어주는 이유는, 우리가 수정하고자 하는 데이터를 찾아야하기 때문에. */}

        <button onClick={() => { deleteUser(value.id) }}>삭제</button>
    </div>))

    return (
        <div className="Managercomponent">
            {/* onchange를 이용해서, 변하는 값을 state로 저장한다. */}
            <input type="text" placeholder='제목' onChange={(event) => { setNewName(event.target.value) }} />
            <input type="number" placeholder='일정' onChange={(event) => { setNewAge(event.target.value) }} />

            <div className="itam1-img-container">


                <div className="Managercomponent-container">
                    {/* onchange를 이용해서, 변하는 값을 state로 저장한다. */}
                    <input className="Managercomponent-container-img-upload-box" type="file" onChange={(event) => { setimgUpload(event.target.files[0]); }} />
                    {/* <input className="Managercomponent-container-title-input-box" type="text" placeholder='제목' onChange={(event) => { setNewTitel(event.target.value) }} />
                        <input className="Managercomponent-container-text-input-box" type="text" placeholder='상세내용' onChange={(event) = > { setNewTest(event.target.value) }} /> */}
                    <button className="Managercomponent-container-upload-box" onClick={async () => { await upload(); const newUserId = await createUsers(); updateUser(newUserId, Number(newAge)); }}>올리기</button>

                    {showUsers}
                </div>
            </div>

        </div>
    );
}

export default Managercomponent;
