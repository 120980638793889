import React from "react";
import Footer from 'pages/Component/Footer';

const Celebrity = () => {
  return (
    <div>
      <Footer />
    </div>
  );
};

export default Celebrity;