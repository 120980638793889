import React from "react";
import { Link } from 'react-router-dom';
// 
import "./Itam1.css"
import book from "pages/Component/img-806/book.png"

// 
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css";
import "swiper/css/navigation";
import 'swiper/css/pagination';
import { Autoplay, Pagination } from "swiper";
// 

const Itam1 = () => {
  // ossam5.tistory.com/263

  return (
    <>
      <div className="itam1-img-container">
        <div className="itam1-harder">
          <h2> 7월의 북클럽</h2>
        </div>
        <Swiper
          slidesPerView={1}
          spaceBetween={10}
          rewind={true}
          pagination={{
            type: 'fraction',
            clickable: true,
          }}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          modules={[Autoplay, Pagination]}
          className="mySwiper"
        >
          <SwiperSlide>
            <Link to="/">
              <div>
                <img className="itam1-img-box" src={book} alt="prop" />
              </div>
            </Link>
          </SwiperSlide>
          <SwiperSlide>
            <Link to="/">
              <div>
                <img className="itam1-img-box" src={book} alt="prop" />
              </div>
            </Link>
          </SwiperSlide>
          <SwiperSlide>
            <Link to="/">
              <div>
                <img className="itam1-img-box" src={book} alt="prop" />
              </div>
            </Link>
          </SwiperSlide>
          <SwiperSlide>
            <Link to="/">
              <div>
                <img className="itam1-img-box" src={book} alt="prop" />
              </div>
            </Link>
          </SwiperSlide>
        </Swiper> 
      </div >
    </>
  );
};

export default Itam1; 