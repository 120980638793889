import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';

import "./Main.css";
import Top from "pages/Component/Top";



import Bookclub from "pages/Component/bookclub/Bookclub";
import Event from "pages/Component/event/Event";
import Celebrity from "pages/Component/celebrity/Celebrity";
import Board from 'pages/Component/board/Board';
import Login from 'pages/Component/login/login';
import SLogin from 'pages/Component/slogin/SLogin';
import Managercomponent from 'pages/ManagerComponent/Managercomponent';
import Loginform from 'pages/Component/login/Loginform';



const Main = () => {
  const [notlogin, setlogin] = useState(true)

  return (
    <div>
      <Top />
      {/* <Managercomponent /> */}
      <Routes>
        <Route path="/" element={<Bookclub />} />
        <Route path="/event" element={<Event />} />
        <Route path="/celebrity" element={<Celebrity />} />
        <Route path="/board" element={<Board />} />
        <Route path="/login" element={<Login />} />
        <Route path='/slogin' element={<SLogin />} />
        <Route path='/loginform' element={<Loginform />} />
      </Routes>
    </div>
  );
};

export default Main;
