import React, { useEffect, useRef, useState } from "react";
import { db, app, auth } from "pages/ManagerComponent/firebase/firebaseDb";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth"
import image from 'pages/Component/img-806/image.png'
import "./login.css";

import Loginform from "pages/Component/login/Loginform";
import Bookclub from "pages/Component/bookclub/Bookclub";
import { ReactComponent as Googlelogo } from 'pages/Component/img-806/Googlelogo.svg'

const Login = () => {
    const [userData, setUserData] = useState(null);


    const user = auth.currentUser;
    if (user !== null) {
        user.providerData.forEach((profile) => {
          console.log("Sign-in provider: " + profile.providerId);
          console.log("  Provider-specific UID: " + profile.uid);
          console.log("  Name: " + profile.displayName);
          console.log("  Email: " + profile.email);
          console.log("  Photo URL: " + profile.photoURL);
        });
      }


    function handleGoogleLogin() {
        const provider = new GoogleAuthProvider(); // provider를 구글로 설정
        signInWithPopup(auth, provider) // popup을 이용한 signup
            .then((data) => {
                setUserData(data.user); // user data 설정
                console.log(user) // console로 들어온 데이터 표시
            })
            .catch((err) => {
                console.log(err);
            });
    }



    return (

        <div className="auth-wrapper">
            <div style={{
                flexDirection: 'column',
                height: '100%'
            }}>
                <div
                    className="hello">
                    <img
                        className="emailhello"
                        src={image}
                        width='100%'
                        height='auto'
                        alt='image-img' />
                </div>
                <Loginform />
                <div className="loginandslogin">
                    <div className="loginandsloginborder"></div>
                    <div className="loginandslogintext">로그인 또는 회원가입</div>
                </div>
                <div className="googlelogin">
                    <button
                        onClick={handleGoogleLogin}
                        className="google"><Googlelogo width="25" height="20" fill="#000" />Google 계정으로 가입하기</button>
                    {userData ? user.displayName : null}
                </div>
            </div>
        </div>
    );

};

export default Login;