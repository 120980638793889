import React from 'react';
import { Link } from 'react-router-dom';
import './Top.css'
import { ReactComponent as Reservation } from "pages/Component/img-806/manu.svg";
import { ReactComponent as Userlogin } from 'pages/Component/img-806/Userlogin.svg'
import logo from 'pages/Component/img-806/logo.png'

const Top = () => {
  return (
    <div className='header'>
      <div className='header-container'>

        <div className='header-container-itam'>
          <div className='header-container-button'>
            <button>
              <Reservation width="35" height="35" fill="#000" />
            </button>
          </div>
          <div className='header-container-logo'>
            <Link to="/"><img src={logo} width='85' height='85' alt='logo' /></Link>
          </div>
          <div className='header-container-userlogin'>
            <Link to="/Login"><Userlogin width="43" height="43" fill="#000" /></Link>
          </div>
        </div>

        <ul className='manu-container'>
          <li className='manu-button' >
              <Link to="/">북클럽</Link>
          </li>
          <li className='manu-button'>
            <Link to="/Event">이벤트</Link>
          </li>
          <li className='manu-button'>
            <Link to="/Celebrity">셀럽</Link>
          </li>
          <li className='manu-button'>
            <Link to="/Board">게시판</Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Top;