import React from 'react';
import ReactDOM from 'react-dom/client';
import './pages/Component/Main.css';
import Main from 'pages/Component/Main';

import { Provider } from "react-redux";
import store from "./pages/redux/store";

import { BrowserRouter } from 'react-router-dom';




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Main />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);
