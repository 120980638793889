import React, { useState, useEffect, useId, useRef } from "react";
import "./Board.css";
import Footer from 'pages/Component/Footer';
// 파이어베이서 파일에서 import 해온 db
import { db, Storage } from 'pages/ManagerComponent/firebase/firebaseDb';
// db에 접근해서 데이터를 꺼내게 도와줄 친구들
import { collection, getDocs, updateDoc, setDoc, serverTimestamp, addDoc, doc, deleteDoc } from "firebase/firestore";


import { getStorage, ref, uploadBytes, listAll, getDownloadURL } from "firebase/storage";



const Board = () => {
    const [newTetel, setNewTetel] = useState("");
    const [newAge] = useState(0);

    const [imgUpload, setimgUpload] = useState(null);
    const [imgList, setImgList] = useState([]);
    const imgListRef = ref(Storage, "imgList/");

    // changed를 true로 바꿔주면 되지않을까?
    const [changed, setChanged] = useState(true);

    // 이따가 users 추가하고 삭제하는거 진행을 도와줄 state
    const [users, setUsers] = useState([]);

    // db의 users 컬렉션을 가져옴
    const usersCollectionRef = collection(db, 'users');


    console.log(newTetel, newAge);
    console.log(usersCollectionRef.data)

    const upload = async () => {
        if (imgUpload === null) return;

        const imgRef = ref(Storage, `imgUpload/${imgUpload.name}`);
        // `images === 참조값이름(폴더이름), / 뒤에는 파일이름 어떻게 지을지
        await uploadBytes(imgRef, imgUpload).then((snapshot) => {
            // 업로드 되자마자 뜨게 만들기
            getDownloadURL(snapshot.ref).then((url) => {
                setImgList((prev) => [...prev, url]);
            });
        });
    };
    useEffect(() => {
        listAll(imgListRef).then((response) => {
            response.items.forEach((item) => {
                getDownloadURL(item).then((url) => {
                    setImgList((prev) => [...prev, url]);
                });
            });
        });
    }, []);
    // 이미지영역

    // 시작될때 한번만 실행
    useEffect(() => {
        // 비동기로 데이터 받을준비
        const getUsers = async () => {
            // getDocs로 컬렉션안에 데이터 가져오기
            const data = await getDocs(usersCollectionRef);
            // users에 data안의 자료 추가. 객체에 id 덮어씌우는거
            setUsers(data.docs.map((doc) => (
                { ...doc.data(), id: doc.id })
            ));
            return setUsers;
        };
        getUsers(true);
        // 뭐든 동작할때마다 changed가 true값으로 변경되니까 화면을 그리고 다시 false로 돌려줘야지 다시 써먹는다.
        setChanged(false)
    }, [changed])// 처음에 한번 그리고, changed가 불릴때마다 화면을 다시 그릴거다


    
    const createUsers = async () => {
        // addDoc을 이용해서 내가 원하는 collection에 내가 원하는 key로 값을 추가한다.
        await addDoc(usersCollectionRef, {
            tetel: newTetel,
            age: Number(newAge), 
            time: serverTimestamp(),
        });
        // 화면 업데이트를 위한 state 변경
        setChanged(true)
    };

    const updateUser = async (id, age) => {
        // 내가 업데이트 하고자 하는 db의 컬렉션의 id를 뒤지면서 내가 수정하고자 하는 id랑 같은 id값을 가진 데이터를 찾는다
        const userDoc = doc(db, "users", id)
        // 내가 업데이트 하고자 하는 key를 어떻게 업데이트할지 준비,, 중요한점이 db에는 문자열로 저장되어있다. 그래서 createUsers()함수안에서 age를 생성할때 숫자열로 형변환 해줘야한다
        const newField = { age: age + 1 };
        // updateDoc()을 이용해서 업데이트
        await updateDoc(userDoc, newField);
        // 화면 업데이트를 위한 state 변경
        setChanged(true)
    }

    // 삭제 - D
    const deleteUser = async (id, age) => {
        // 내가 삭제하고자 하는 db의 컬렉션의 id를 뒤지면서 데이터를 찾는다
        const userDoc = doc(db, "users", id);
        // deleteDoc을 이용해서 삭제
        const deletField = { age: age - 1 };
        await deleteDoc(userDoc, deletField);
        // 화면 업데이트를 위한 state 변경
        setChanged(true)
    }
    // 추가 - D

    const showUsers = users.map((value) => (
        <div key={value.id}>
            <h1>제목: {value.tetel}</h1>
            <h1>게시글: {value.age} 개 </h1>
            {/* 증가버튼은 이 안에 있어야지, 각기 다른 데이터마다 붙는다, users data를 map으로 돌기때문에, 그 안의 id랑 age를 넣어주면 된다.*/}
            {/* id를 넣어주는 이유는, 우리가 수정하고자 하는 데이터를 찾아야하기 때문에. */}
            <button onClick={() => { deleteUser(value.id, value.age, { createUsers }) }}>삭제</button>
            <button onClick={() => { updateUser(value.id, value.age, { createUsers }, ) }}>좋아요</button>
        </div>))
// { upload }
    return (
        <>
            <div>
                <div className="Board-container">
                    <input
                        type="file"
                        onChange={(e) => {
                            setimgUpload(e.target.files[1]);
                        }}
                    />
                    <input
                        className="Board-container-title-input-box"
                        type="text"
                        placeholder='제목'
                        onChange={(e) => { setNewTetel(e.target.value) }}
                    />
                    <button onClick={() => { createUsers(); }}>올리기</button>
                    {imgList.map((el) => {
                        return <img key={el} src={el} />;
                    })}
                    {showUsers}
                </div>
                <Footer />
            </div >
        </>
    );
};
export default Board;