import React, {useState} from 'react';
import Footer from 'pages/Component/Footer';
import Itam1 from 'pages/Component/bookclub/Itam1';
import Itam2 from 'pages/Component/bookclub/Itam2';


const Bookclub = () => {
  const [notlogin, setlogin] = useState(true)
  return (

    <div>
      <div className='bookciub'>
        <Itam1 notlogin={notlogin}/>
      </div>
      <div className='bookclub-itam'>
        <Itam2 />
      </div>

      <h1>북클럽북클럽북클럽북클럽북클럽북클럽북클럽북클럽북클럽</h1>
      <p>북클럽북클럽북클럽북클럽북클럽북클럽북클럽북클럽북클럽</p>
      <div>
      <Footer />
      </div>
    </div>
  );
};

export default Bookclub;