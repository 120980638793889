import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { createUserWithEmailAndPassword, RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { auth } from "pages/ManagerComponent/firebase/firebaseDb";
import Loginform from "../login/Loginform";
import "./SLogin.css"







const SLogin = () => {

    const PonVerifier = () => {
        window.recaptchaVerifier = new RecaptchaVerifier(
            'recaptcha-container',
            {},
            auth,
        );
        const ManagerPonNumber = getPhoneNumberFromUserInput()
        const ManagerVerifie = window.recaptchaVerifier;
        signInWithPhoneNumber(auth, ManagerPonNumber, ManagerVerifie)
            .then((confirmationResult) => {
                window.confirmationResult = confirmationResult;
            }).catch((error) => {
                alert("인증을 완료해주세요.")
            });
    };

    const HandlerSign_Up_Pon = () => {
        const code = ponValid;
        window.confirmationResult
            .confirm(code)
            .then((result) => {
                // User signed in successfully.
                const user = result.user;
                console.log("ㅎㅇ");
                // ...
            })
            .catch((error) => {
                // User couldn't sign in (bad verification code?)
                // ...
            });
    }

    const ponNum = (e) => {
        const value = phoneRef.current.value.replace(/\D+/g, "");
        const numberLength = 8;
        let result;
        result = "";
        for (let i = 0; i < value.length && i < numberLength; i++) {
            switch (i) {
                case 4:
                    result += "-";
                    break;
                default:
                    break;
            }
            result += value[i];
        }
        phoneRef.current.value = result;
        setPon(e.target.value);
    };

    const phoneRef = useRef();
    const [pon, setPon] = useState("")
    const [ponValid, setPonValid] = useState("")

    const getPhoneNumberFromUserInput = () => {
        const ggy = "82+10"
        
    };
    auth.languageCode = 'ko';




    const [sign_up_email, setSign_Up_Email] = useState("")
    const [sign_up_password, setSign_Up_Password] = useState("")
    const [sign_up_passwordcheck, setSign_Up_PasswordCheck] = useState("")


    const [sign_up_emailValid, setSign_Up_EmailValid] = useState(false)
    const [sign_up_passwordValid, setSign_Up_PasswordValid] = useState(false)
    const [sign_up_passwordandpassword, setSign_Up_PasswordAndPassword] = useState(false)



    const [errorMsg, setErrorMsg] = useState("");

    const [sign_up_submitButton, setSign_Up_SubmitButton] = useState(true)



    const onSubmit = async () => {
        try {
            const createdUser = await createUserWithEmailAndPassword(
                auth, sign_up_email, sign_up_password);
            console.log(createdUser)
        } catch (err) {
            console.log(err.code)
            switch (err.code) {
                case 'auth/email-already-in-use':
                    setErrorMsg('이미 가입되어 있는 계정입니다');
                    break;
            }
        }
    }

    const HandlerSign_Up_Email = (e) => {
        setSign_Up_Email(e.target.value);
        const regex =
            /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
        if (regex.test(e.target.value)) {
            setSign_Up_EmailValid(true)
        } else {
            setSign_Up_EmailValid(false)
        }
    };

    const HandlerSign_Up_Password = (e) => {
        setSign_Up_Password(e.target.value);
        const regex =
            /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,}$/;
        if (regex.test(e.target.value)) {
            setSign_Up_PasswordValid(true);
        } else {
            setSign_Up_PasswordValid(false);
        } return HandlerSign_Up_Password;
    };

    const PasswordAndPassword = (e) => {
        const sign_up_id_pwcheck = e.target.value
        setSign_Up_PasswordCheck(sign_up_id_pwcheck);
        if (sign_up_password === sign_up_id_pwcheck) {
            setSign_Up_PasswordAndPassword(true)
        } else {
            setSign_Up_PasswordAndPassword(false);
        } return PasswordAndPassword;
    };



    useEffect(() => {
        if (sign_up_password !== sign_up_passwordcheck) {
            setSign_Up_PasswordAndPassword(false);
        }
    }, [sign_up_password]);

    useEffect(() => {
        if (sign_up_emailValid && sign_up_passwordValid && sign_up_passwordandpassword) {
            setSign_Up_SubmitButton(false);
        } else {
            setSign_Up_SubmitButton(true);
        }
    }, [sign_up_emailValid, sign_up_passwordValid, sign_up_passwordandpassword])

    console.log(sign_up_email, sign_up_password, sign_up_passwordcheck)

    const [allAgreed, setAllAgreed] = useState(false);
    const [agreements, setAgreements] = useState({
        termsAgreed: false,
        personalInfoAgreed: false,
        provisionAgreed: false,
        locationAgreed: false,
        eventAlarmAgreed: false,
        serviceAlarmAgreed: false,
    });

    const handleAgreementChange = (event) => {
        const { name, checked } = event.target;

        setAgreements((prevAgreements) => ({ ...prevAgreements, [name]: checked }));
        const allChecked = Object.values({ ...agreements, [name]: checked }).every(
            (value) => value === true
        );
        setAllAgreed(allChecked);
    };

    const handleAllAgreementChange = (event) => {
        const { checked } = event.target;
        setAgreements((prevAgreements) =>
            Object.keys(prevAgreements).reduce(
                (newAgreements, agreementKey) => ({
                    ...newAgreements,
                    [agreementKey]: checked,
                }),
                {}
            )
        );
        setAllAgreed(checked);
    };




    return (
        <div className="sign_up">
            <div>
                <form
                    className="sign_up_eamilpasswordinput"
                >
                    <div className="sign_up_title">
                        {/* <div
                            style={{
                                fontSize: "20px",
                                paddingBottom: "10px",
                                color: "#000"
                            }}>함께하는 즐거움!</div>
                        <div
                            style={{
                                fontSize: "20px",
                                color: "#000"
                            }}>문학살롱에 오신 것을 환영합니다!
                        </div> */}
                    </div>
                    <div>
                        <div style={{
                            textAlign: "start",
                            fontSize: "20px",
                            height: "60px"
                        }}>
                            로그인에 사용할 이메일과 비밀번호를 입력해주세요.
                        </div>
                        <div className="sign_up_test">
                            이메일
                        </div>
                        <input
                            className="sign_up_mailinput"
                            placeholder="실제 사용하시는 이메일을 등록해주세요."
                            type="email"
                            value={sign_up_email}
                            onChange={HandlerSign_Up_Email} />
                        <div
                            style={{
                                marginLeft: "10px",
                                fontSize: "13px",
                                color: "#ef4545"
                            }}>
                            {!sign_up_emailValid && sign_up_email.length > 0 && (
                                <div>올바른 이메일을 입력해주세요.</div>
                            )}
                        </div>
                    </div>
                    <div>
                        <div className="sign_up_test">
                            비밀번호
                        </div>
                        <input
                            className="sign_up_passwordinput"
                            placeholder="사용하실 비밀번호"
                            type="password"
                            value={sign_up_password}
                            onChange={HandlerSign_Up_Password} />
                        <div
                            style={{
                                marginLeft: "10px",
                                fontSize: "13px",
                                color: "#ef4545"
                            }}>
                            {!sign_up_passwordValid && sign_up_password.length > 0 && (
                                <div>영문, 숫자,특수문자 포함 8글자 입력해주세요.</div>
                            )}
                        </div>
                        <div>
                            <input
                                className="sign_up_passwordinput"
                                placeholder="비밀번호 확인"
                                type="password"
                                value={sign_up_passwordcheck}
                                onChange={PasswordAndPassword}
                            />
                            <div style={{
                                marginLeft: "10px",
                                fontSize: "13px",
                                color: "#03c75a"
                            }}>
                                {sign_up_passwordandpassword && sign_up_passwordcheck.length > 0 && (
                                    <div>비밀번호가 일치합니다.</div>
                                )}
                            </div>
                            <div style={{
                                marginLeft: "10px",
                                fontSize: "13px",
                                color: "#ef4545"
                            }}>
                                {!sign_up_passwordandpassword && sign_up_passwordcheck.length > 0 && (
                                    <div>비밀번호를 확인해주세요.</div>
                                )}
                            </div>
                        </div>
                        <div
                            className="proof">
                            <div>
                                <p className="sign_up_test">본인인증</p>
                                <div className="proofborder"></div>
                                <div className="prooftext">
                                    <div>
                                        문학살롱은 실명 커뮤니티를 운영중입니다.
                                    </div>
                                    <div>
                                        원활한 소통을 위해 본인인증을 해주세요.
                                    </div>
                                </div>

                                <div></div>
                                <div id="recaptcha-container" ></div>
                                <input
                                    type="tel"
                                    name="user-number"
                                    value={pon}
                                    ref={phoneRef}
                                    onChange={ponNum}
                                    placeholder="예: 1234-5678"
                                />
                                <button onClick={PonVerifier}>
                                    문자보내기
                                </button>
                                <input
                                    onChange={(e) => setPonValid(e.target.value !== "" ? "valid" : "")}
                                    type="text"
                                />
                                <button
                                    onClick={HandlerSign_Up_Pon}>
                                    인증번호 확인하기
                                </button>
                            </div>
                            <div
                                className="sign_up_id">
                                <div
                                    className="sign_up_pwfind">
                                </div>
                            </div>
                        </div>

                    </div>



                    <div className="sign_up_checkbox">
                        <label className="sign_up_test">회원 정보 입력 및 이용약관 동의</label>
                        <ul>
                            <li className="sign_up_checkbox_all_box">
                                <input
                                    type="checkbox"
                                    id="agree_check_all"
                                    name="agree_check_all"
                                    checked={allAgreed}
                                    onChange={handleAllAgreementChange}
                                />
                                <label htmlFor="agree_check_all">이용약관 전체동의</label>
                            </li>
                            <li className="sign_up_checkbox_box">
                                <input
                                    type="checkbox"
                                    id="agree_check_used"
                                    name="termsAgreed"
                                    required
                                    checked={agreements.termsAgreed}
                                    onChange={handleAgreementChange}
                                />
                                <label htmlFor="agree_check_used">
                                    [필수] 이용약관 동의
                                </label>
                            </li>
                            <li className="sign_up_checkbox_box">
                                <input
                                    type="checkbox"
                                    id="agree_check_info"
                                    name="personalInfoAgreed"
                                    required
                                    checked={agreements.personalInfoAgreed}
                                    onChange={handleAgreementChange}
                                />
                                <label htmlFor="agree_check_info">
                                    [필수] 개인정보 이용 수집 방침
                                </label>

                            </li>
                            <li className="sign_up_checkbox_box">
                                <input
                                    type="checkbox"
                                    id="agree_check_info_other"
                                    name="provisionAgreed"
                                    required
                                    checked={agreements.provisionAgreed}
                                    onChange={handleAgreementChange}
                                />
                                <label htmlFor="agree_check_info_other">
                                    [필수] 개인정보 제 3자 제공 동의
                                </label>

                            </li>
                            <li className="sign_up_checkbox_box">
                                <input
                                    type="checkbox"
                                    id="agree_check_pos"
                                    name="locationAgreed"
                                    required
                                    checked={agreements.locationAgreed}
                                    onChange={handleAgreementChange}
                                />
                                <label htmlFor="agree_check_pos">[필수] 위치정보 동의 약관</label>

                            </li>
                            <li className="sign_up_checkbox_box">
                                <input
                                    type="checkbox"
                                    id="agree_check_event_receive"
                                    name="eventAlarmAgreed"
                                    checked={agreements.eventAlarmAgreed}
                                    onChange={handleAgreementChange}
                                />
                                <label htmlFor="agree_check_event_receive">
                                    [선택] 이벤트 및 혜택 알림 수신 동의
                                </label>

                            </li>
                            <li className="sign_up_checkbox_box">
                                <input
                                    type="checkbox"
                                    id="agree_check_push"
                                    name="serviceAlarmAgreed"
                                    checked={agreements.serviceAlarmAgreed}
                                    onChange={handleAgreementChange}
                                />
                                <label htmlFor="agree_check_push">
                                    [선택] 서비스 알림 수신 동의
                                </label>
                            </li>
                        </ul>
                    </div>



                    <button
                        className="sign_up_submitbutton"
                        type="submit"
                        disabled={sign_up_submitButton}
                        onClick={onSubmit}>
                        회원가입
                    </button>
                </form>
            </div>
        </div>
    );
};

export default SLogin;
